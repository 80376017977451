<template>
  <v-main>
    <VideoComponentPcm />
    <pcm-aseg />
    <pcm-bseg />
    <pcm-cseg />
    <pcm-dseg />
    <white-label />
    <Footer />
  </v-main>
</template>

<script>
import Footer from "@/components/footer";
import VideoComponentPcm from "@/components/video/VideoComponentPcm";
import pcmAseg from "@/components/pcm/pcmAseg";
import pcmBseg from "@/components/pcm/pcmBseg";
import pcmCseg from "@/components/pcm/pcmCseg";
import pcmDseg from "@/components/pcm/pcmDseg";
import whiteLabel from "@/components/whiteLabel";
export default {
  name: "PersContMark",
  components: {
    Footer,
    VideoComponentPcm,
    pcmAseg,
    pcmBseg,
    pcmCseg,
    pcmDseg,
    whiteLabel,
  },
};
</script>
<style scoped></style>

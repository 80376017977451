<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small text color="primary" v-bind="attrs" v-on="on">
        lees verder
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon color="primary" @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="primary" class="btnAn" @click="closeWin">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title class="primary--text">
        Personal Content Marketing
      </v-card-title>

      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl lanceert Personal Content Marketing <br />
        <br />Personal Content Marketing biedt accountantskantoren nieuwe
        digitale mogelijkheden om de customer journey in te richten. Hoe zorgt u
        ervoor dat uw kantoor kennis ontsluit die persoonlijk, toegankelijk en
        direct toepasbaar is voor uw klanten? <br />
        <br />Personal Content Marketing biedt de mogelijkheid om met digitale
        tools de klantbehoeften te verkennen en in te vullen in een fractie van
        de tijd die je er nu aan besteed.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "newsA",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeWin() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>

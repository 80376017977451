<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-12 mt-5 col-md-6 align-center justify-center">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          Personal Content Marketing
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Personal Content Marketing is een verdieping op Content Marketing en
          maakt het mogelijk om thema’s of vraagstukken te vertalen naar
          persoonlijke en direct toepasbare informatie voor je klant. Waar
          corporate storytelling en bijbehorende content marketing geschreven
          zijn voor een breed publiek, gaat het bij Personal Content Marketing
          om het toepassen van de informatie bij de klant, zodat deze voor
          zichzelf de afweging kan maken of het vraagstuk urgent en belangrijk
          genoeg is om hieraan aandacht te besteden.<br /><br />
          <dialog-pcm-video></dialog-pcm-video>
          Bekijk de animatie
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6">
      <v-img
        :src="require('@/assets/pcm/pcm_sega2a.jpg')"
        width="100%"
        class="mt-5"
        height="40vh"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import dialogPcmVideo from "@/components/pcm/dialogPcmVideo";
export default {
  name: "pcmAseg",
  components: { dialogPcmVideo },
};
</script>

<style scoped></style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import "animate.css";
import vueVimeoPlayer from "vue-vimeo-player";

Vue.use(vueVimeoPlayer);
Vue.config.productionTip = false;
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

axios.defaults.baseURL = "https://pcm-tools.nl/api";
// axios.defaults.baseURL = "http://localhost/api";

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: (h) => h(App),
}).$mount("#app");

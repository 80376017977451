<template>
  <v-row justify="center" no-gutters>
    <kenm-a />
    <kenm-b />
    <kenm-c />
    <kenm-d />
    <kenm-e />
    <kenm-f />
  </v-row>
</template>

<script>
import kenmA from "@/components/famstat/kenmA";
import kenmB from "@/components/famstat/kenmB";
import kenmC from "@/components/famstat/kenmC";
import kenmD from "@/components/famstat/kenmD";
import kenmE from "@/components/famstat/kenmE";
import kenmF from "@/components/famstat/kenmF";
export default {
  name: "famBseg",
  components: { kenmA, kenmB, kenmC, kenmD, kenmE, kenmF },
};
</script>

<style scoped></style>

<template>
  <v-row no-gutters>
    <v-col class="hidden-sm-and-down col-md-6 mb-5">
      <v-img
        :src="require('@/assets/pcm/pcm_segb.jpg')"
        width="100%"
        class="mt-5"
        height="70vh"
      >
      </v-img>
    </v-col>

    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text"> Personal Quick Scan </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Een Personal Quick Scan is een mini app, specifiek voor de klant en
          geladen met één of meerdere thema's of vraagstukken. Aan de hand van
          visuals en vragen kan de klant zich hierop oriënteren en beoordelen of
          het voor hem van toepassing is. Deze methode zorgt op een
          gebruiksvriendelijke en snelle manier voor het delen van kennis, het
          creëren van awareness en interesse. De data uit de app vertaalt zich
          naar bijvoorbeeld een rapport of personal white paper voor de klant en
          een PowerPoint Presentatie die de adviseur kan gebruiken voor het
          vervolggesprek. <br />
          <br />Wil je weten hoe de Personal Quick Scan werkt? Neem contact met
          ons op.<br />
          <br />

          <br /><br />
        </v-card-text> </v-card
      >∏
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "pcmBseg",
  components: {},
};
</script>

<style scoped></style>

<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="primary">
                <v-icon> mdi-book-information-variant </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center secondary--text"
              >Essentiële bedrijfsinformatie</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  Voor veel MKB ondernemers geldt dat veel informatie in het
                  hoofd zit. Als de MKB ondernemer wegvalt, is die informatie
                  niet meer bereikbaar. Het Noodplan inventariseert op een
                  gestructureerde wijze deze informatie en legt dit vast in een
                  aantallen rapporten, die tijdens een noodsituatie direct
                  houvast bieden.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "noodKenD",
};
</script>

<style scoped></style>

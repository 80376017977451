<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text"> Bedrijfsprofiel </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Trustedaccountant.nl richt zich als serviceprovider op de grote en
          middelgrote accountantskantoren, die willen investeren in het
          vergroten van de effectiviteit binnen de adviespraktijk. Hiertoe
          leveren wij online oplossingen met moderne technieken om klantbeleving
          en -loyaliteit te versterken.
          <br /><br />
          Hiernaast leveren wij (digitale) producten om specifieke vraagstukken
          rondom de leiding en het bestuur over vermogen vorm te geven, waarbij
          verschillende stakeholders in het besluitvormingsproces betrokken
          kunnen worden. Onze oplossingen worden geleverd in de huisstijl van
          het accountantskantoor. Op het gebied van databescherming en
          -veiligheid hanteren wij de hoogste normen.
          <br /><br />
          Onze ambitie is om een internationale serviceprovider voor de
          financiële dienstverlening te zijn, die op een persoonlijke wijze
          samenwerkt en producten bouwt die verder vorm geven aan de digitale
          transitie waar de sector mee te maken heeft.
          <br /><br />
          Trustedaccountant.nl is een handelsnaam van Laapheer BV.<br />
          Laapheer BV is gevestigd aan de W. Vrijlandtstraat 8, 3262 GN te
          Oud-Beijerland<br />
          Kamer van Koophandel nr 56449593<br />BTW nummer NL852130648B01
          <br />Bankrekening Rabobank NL36RABO0174923678<br />
          <br />

          <pro-priv />
          <profile-alg />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/pcm/pcm_sega2a.jpg')"
        width="100%"
        class="mt-5"
        height="50vh"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import proPriv from "@/components/profile/proPriv";
import ProfileAlg from "@/components/profile/profileAlg";

export default {
  name: "profileAseg",
  components: {
    ProfileAlg,
    proPriv,
  },
};
</script>
<style scoped></style>

<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="primary">
                <v-icon> mdi-face-man </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center secondary--text"
              >Waarnemer en opvolger kiezen</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  Eén van de belangrijkste stappen is iemand aan te wijzen die
                  de ondernemer kan waarnemen of opvolgen als de ondernemer niet
                  in staat is om leiding te geven aan het bedrijf. Voor de
                  ondernemer is dit een lastige keuze. Wie is geschikt? Wie wil
                  overnemen? Je kan de ondernemer bij deze keuze helpen.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "noodKenB",
};
</script>

<style scoped></style>

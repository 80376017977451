<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="primary">
                <v-icon> mdi-folder-information </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center secondary--text"
              >Wijzigingen vastleggen</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  Een MKB bedrijf is constant in beweging. De MKB ondernemer
                  heeft te maken met veel veranderingen die zijn oorspronkelijke
                  wensen over de toekomst van het bedrijf kunnen raken. Met het
                  Noodplan hou je dit thema samen met de ondernemer steeds tegen
                  het licht. Wijzigingen zijn eenvoudig vast te leggen, waardoor
                  in een crisissituatie altijd een actueel houvast aanwezig is.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "noodKenF",
};
</script>

<style scoped></style>

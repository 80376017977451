<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5 mb-5">
        <v-card-title class="primary--text">
          NOODOPVOLGING: VERKLEIN DE IMPACT
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Bij veel MKB bedrijven is de bedrijfscontinuïteit sterk verbonden aan
          de ondernemer zelf. Stel dat de ondernemer door een ongeval of ziekte
          langere tijd niet kan ondernemen. Wie zorgt er dan voor dat het
          bedrijf? De partner van de ondernemer? De kinderen? Zijn zij in staat
          om zelfstandig het bedrijf voort te zetten? Wat moet zij dan weten en
          kunnen? <br />
          <br />Deze en andere vragen leven rondom noodopvolging. Je hebt als
          adviseur een unieke positie om de impact van noodopvolging te
          verkleinen. Met ons Noodplan leg je de toekomstvisie en
          eigendomsstrategie van de ondernemer vast. Hieraan koppel je je advies
          en begeleiding om de maatregelen die genomen moeten worden in te
          voeren. Het Noodplan is een handleiding voor het gezin van de
          ondernemer, die daarmee duidelijkheid heeft.<br />
          <br />

          <dialog-nood-video />
          Bekijk de animatie
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_prodnood2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import dialogNoodVideo from "@/components/noodplan/dialogNoodVideo";
export default {
  name: "noodAseg",
  components: { dialogNoodVideo },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./kenmA.vue?vue&type=template&id=b2dd43be&scoped=true&"
import script from "./kenmA.vue?vue&type=script&lang=js&"
export * from "./kenmA.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2dd43be",
  null
  
)

export default component.exports
<template>
  <v-row no-gutters>
    <v-col class="hidden-sm-and-down col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_pcm2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>

    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          Personal Content Marketing
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Personal Content Marketing stelt je in staat om snel stappen te nemen
          bij het inrichten van customer journeys enerzijds en digitale tooling
          voor jouw adviseurs anderzijds. <br /><br />Met onze technologie laat
          je de klant zelf bepalen welke vraagstukken voor hem urgent en
          belangrijk zijn. Het startpunt hiervan kan liggen in jouw corporate
          website, jouw social media campagnes of bij de adviseur. Je weet dus
          wat de aandacht krijgt van jouw klant. Je kunt daar vervolgens
          effectiever op in spelen.<br /><br />

          <dialog-pcm-video></dialog-pcm-video>
          Bekijk de animatie
          <v-btn plain color="secondary" @click="$router.push('pcm')"
            >of lees snel verder</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import dialogPcmVideo from "@/components/pcm/dialogPcmVideo";
export default {
  name: "homeBseg",
  components: { dialogPcmVideo },
};
</script>

<style scoped>
.btnAn {
  animation-duration: 20s;
}
</style>

<template>
  <v-main>
    <VideoComponentVermregie />
    <verm-aseg />
    <verm-bseg />
    <white-label />
    <Footer />
  </v-main>
</template>

<script>
import Footer from "@/components/footer";
import VideoComponentVermregie from "@/components/video/VideoComponentVermregie";
import vermAseg from "@/components/vermregie/vermAseg";
import vermBseg from "@/components/vermregie/vermBseg";
import WhiteLabel from "@/components/whiteLabel";
export default {
  name: "Noodplan",
  components: {
    WhiteLabel,
    VideoComponentVermregie,
    vermAseg,
    vermBseg,
    Footer,
  },
};
</script>
<style scoped></style>

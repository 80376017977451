<template>
  <div>
    <v-dialog v-model="dialog" width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text color="primary" v-bind="attrs" v-on="on">
          lees verder
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary--text"> Familiestatuut App </v-card-title>

        <v-card-text class="body-1 grey--text text--darken-2">
          Familiestatuut App, de consultancy methode van de toekomst. <br />
          <br />
          In de B.C.-periode (Before Corona) ontwikkelde Trustedaccountant.nl al
          een nieuwe consultancymethode die complexe groepsprocessen zoals het
          familiestatuut begeleidt met behulp van digitale tooling.<br />
          <br />Deze nieuwe methode leidde al tot aanzienlijke successen bij
          accountantskantoren zoals Ernst & Young en FLYNTH. Klanten roemen de
          mogelijkheid om de verschillende perspectieven op het familiebedrijf
          in eigen tijd en tempo te lezen en antwoord te geven op de
          bijbehorende stellingen.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "newsB",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>

<template>
  <v-col class="col-12 col-md-4 d-flex">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/home/home_prodfam2.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Familiestatuut</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Het Familiestatuut, -convenant of -charter helpt de spelregels over
            samen werken, leiden en besturen als familieleden in het bedrijf
            vast te leggen. Met behulp van onze technologie ontwikkelden wij een
            modern afstemmingsproces, waardoor het past binnen het budget van
            middelgrote en kleinere familiebedrijven.
          </v-card-text>
          <v-card-actions class="hidden-md-and-up">
            <v-btn class="accent" @click="$router.push('familiestatuut')"
              >Lees verder</v-btn
            >
          </v-card-actions>

          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="warning">
              <v-btn class="accent" @click="$router.push('familiestatuut')"
                >Lees verder</v-btn
              >
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  name: "prodFam",
};
</script>

<style scoped></style>

<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="primary">
                <v-icon> mdi-bookshelf </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center secondary--text"
              >Kennis aanbieden</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  De Familiestatuut App biedt alle betrokken familieleden op een
                  identieke wijze kennis aan. Kennis in heldere taal, korte en
                  bondige uitleg en waar mogelijk met visuele ondersteuning. Het
                  tempo waarin kennis wordt opgedaan, wordt bepaald door het
                  individuele familielid. Zo maken we de ervaring op maat voor
                  alle familieleden met als doel goed geïnformeerd beslissingen
                  nemen als individu en werken vanuit eenzelfde beeld en met
                  dezelfde taal als groep.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "kenmE",
};
</script>

<style scoped></style>

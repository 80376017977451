<template>
  <div>
    <v-toolbar>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-btn text class="primary-text" @click="goHome">
        <v-icon left class="primary--text">mdi-home</v-icon>
        <span class="primary--text">Home</span></v-btn
      >
      <v-btn text class="secondary--text hidden-sm-and-down" @click="goPCM">
        <v-icon left>mdi-account-alert</v-icon>
        <span>Personal Content Marketing</span>
      </v-btn>
      <div class="hidden-sm-and-down">
        <v-menu text offset-y>
          <template v-slot:activator="{ on }">
            <!-- <v-btn text slot="activator"> -->
            <v-btn text color="secondary" v-on="on">
              <v-icon left>mdi-card-bulleted-settings-outline</v-icon>
              <span>Producten</span>
              <v-icon left>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/familiestatuut">
              <v-list-item-icon>
                <v-icon class="secondary--text">mdi-book-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="secondary--text"
                >Familiestatuut</v-list-item-title
              >
            </v-list-item>

            <v-list-item to="/noodplan">
              <v-list-item-icon>
                <v-icon class="secondary--text">mdi-medical-bag</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="secondary--text"
                >Noodplan</v-list-item-title
              >
            </v-list-item>

            <v-list-item to="/vermogensregie">
              <v-list-item-icon>
                <v-icon class="secondary--text">mdi-cash-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="secondary--text"
                >Vermogensregie</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-btn
        text
        color="secondary"
        class="hidden-sm-and-down"
        @click="goProfile"
      >
        <v-icon left>mdi-state-machine</v-icon>
        <span>Bedrijfsprofiel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text @click="goDutch">
        <v-icon left color="roodt">mdi-flag</v-icon>
        <span>EN</span>
      </v-btn>
      <v-btn text @click="toggle_dark_mode">
        <v-icon left color="roodt">mdi-theme-light-dark</v-icon>
        <span>Donker/Licht</span>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" app temporary class="mt-15">
      <v-list dense nav>
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon text color="primary">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title text class="primary--text"
              >Home</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/pcm">
            <v-list-item-icon>
              <v-icon text color="secondary">mdi-account-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-title text color="secondary"
              >Personal Content Marketing</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/familiestatuut">
            <v-list-item-icon>
              <v-icon text color="secondary">mdi-book-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title text color="secondary"
              >Familiestatuut</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/noodplan">
            <v-list-item-icon>
              <v-icon text color="secondary">mdi-medical-bag</v-icon>
            </v-list-item-icon>
            <v-list-item-title text color="secondary"
              >Noodplan</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/vermogensregie">
            <v-list-item-icon>
              <v-icon text color="secondary">mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title text color="secondary"
              >Vermogensregie</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/profiel">
            <v-list-item-icon>
              <v-icon text color="secondary">mdi-state-machine</v-icon>
            </v-list-item-icon>
            <v-list-item-title text color="secondary"
              >Bedrijfsprofiel</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "toolbar",
  data() {
    return {
      drawer: false,
      theme: null,
    };
  },
  computed: {
    // eslint-disable-next-line no-unreachable
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    goDutch() {
      window.open("https://trusted-accountant.com", "_blank");
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
    goPCM() {
      this.$router.push({ name: "pcm" });
    },
    goProfile() {
      this.$router.push({ name: "profiel" });
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
};
</script>
<style scoped></style>

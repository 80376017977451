import { render, staticRenderFns } from "./Noodplan.vue?vue&type=template&id=ff93d5de&scoped=true&"
import script from "./Noodplan.vue?vue&type=script&lang=js&"
export * from "./Noodplan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff93d5de",
  null
  
)

export default component.exports
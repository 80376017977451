<template>
  <div>
    <h2 class="secondary--text mb-3 mt-5">Kwetsbaar</h2>
    <div class="body-1 grey--text text--darken-2">
      Juist in Corona tijd ervaren we hoe kwetsbaar we zijn.......
    </div>
  </div>
</template>

<script>
export default {
  name: "headerC",
};
</script>

<style scoped></style>

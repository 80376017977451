<template>
  <v-main>
    <VideoComponentHome />
    <!--    <div class="secondary">-->
    <!--      <v-row>-->
    <!--        <v-col-->
    <!--          cols="3"-->
    <!--          justify="center"-->
    <!--          align="center"-->
    <!--          class="hidden-sm-and-down"-->
    <!--        >-->
    <!--          <div class="mt-8 pb-10">-->
    <!--            <v-img-->
    <!--              :src="require('@/assets/home/proef.svg')"-->
    <!--              height="100px"-->
    <!--              width="100px"-->
    <!--              class="mt-5"-->
    <!--            >-->
    <!--            </v-img>-->
    <!--            <h3 class="grey&#45;&#45;text mt-2">PERSOONLIJK</h3>-->
    <!--            <v-divider class=" primary ml-10 mr-10 mt-8"></v-divider>-->
    <!--            <v-img-->
    <!--              :src="require('@/assets/home/proef.svg')"-->
    <!--              height="100px"-->
    <!--              width="100px"-->
    <!--              class="mt-5"-->
    <!--            >-->
    <!--            </v-img>-->
    <!--            <h3 class="mt-2">INHOUD</h3>-->
    <!--            <v-divider class=" primary ml-10 mr-10 mt-8"></v-divider>-->
    <!--            <v-img-->
    <!--              :src="require('@/assets/home/proef2.svg')"-->
    <!--              height="100px"-->
    <!--              width="100px"-->
    <!--              class="mt-5"-->
    <!--            >-->
    <!--            </v-img>-->
    <!--            <h3 class="mt-2">24/7</h3>-->
    <!--          </div>-->
    <!--        </v-col>-->
    <!--        <v-col-->
    <!--          cols="1"-->
    <!--          justify="left"-->
    <!--          align="center"-->
    <!--          class="mt-10 mb-10 hidden-sm-and-down"-->
    <!--        >-->
    <!--          <v-divider vertical class="primary"></v-divider>-->
    <!--        </v-col>-->
    <!--        <v-col class="col-12 mt-15 col-md-8 pr-8 ">-->
    <!--          <v-card-title class="primary&#45;&#45;text">-->
    <!--            The Future of Advice</v-card-title-->
    <!--          >-->
    <!--          <v-card-text class=" body-1 grey&#45;&#45;text text&#45;&#45;lighten-2"-->
    <!--            >Uw klant verwacht dat u in staat bent om 24/7 op een persoonlijke-->
    <!--            en effectieve wijze informatie te delen. Via het device en het-->
    <!--            kanaal dat uw klant kiest. Waarbij de klant in staat wil zijn om in-->
    <!--            eigen tijd en tempo dit te doorgronden en te reageren. De klant als-->
    <!--            regisseur van het 'gesprek'.<br /><br />-->
    <!--            In de nabije toekomst kunnen ‘robo-advisors’ en chatrobots een deel-->
    <!--            van de kennisdeling van u overnemen. Zover is het nog niet, maar-->
    <!--            duidelijk is dat u wordt uitgedaagd om nieuwe methoden te vinden om-->
    <!--            tegemoet te komen aan de communicatiebehoeften van uw klant. Onze-->
    <!--            technologie helpt u om ..-->
    <!--          </v-card-text>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </div>-->

    <HomeSegmentA />
    <HomeSegmentB />
    <HomeSegmentC />
    <HomeSegmentE />
    <white-label />
    <HomeSegmentD />

    <Footer />
  </v-main>
</template>

<script>
import Footer from "@/components/footer";
import VideoComponentHome from "@/components/video/VideoComponentHome";
import HomeSegmentA from "@/components/home/homeAseg";
import HomeSegmentB from "@/components/home/homeBseg";
import HomeSegmentC from "@/components/home/homeCseg";
import HomeSegmentD from "@/components/home/homeDseg";
import HomeSegmentE from "@/components/home/homeEseg";
import whiteLabel from "@/components/whiteLabel";

export default {
  name: "Home",
  components: {
    Footer,
    VideoComponentHome,
    HomeSegmentA,
    HomeSegmentB,
    HomeSegmentC,
    HomeSegmentD,
    HomeSegmentE,
    whiteLabel,
  },
};
</script>
<style scoped>
.part1 {
  height: 1200px;
}
</style>

<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5 mb-5">
        <v-card-title class="primary--text"> Familiestatuut </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Veel familiebedrijven ervaren het traject van een familiestatuut als
          zinvol, maar ook als tijdrovend en duur. Dat heeft dan vooral te maken
          met de tijdsinvestering die van hen gevraagd wordt om met een
          consultant inventarisatiegesprekken voorafgaand aan het familieoverleg
          te voeren. <br />
          <br />Wij hebben dit klassieke consultancy traject vervangen door
          slimme technologie. Met de Familiestatuut App kunnen de familieleden
          zelfstandig in hun eigen tempo en tijd aan de slag. Met deze moderne
          aanpak bereik je een hoge betrokkenheid bij de individuele
          familieleden, dat men onderling dezelfde taal spreekt en dat de
          besluitvorming op een gestructureerde manier wordt vastgelegd. <br />
          <br />Vanuit de data uit de Familiestatuut App bereid je het
          familieoverleg voor. Doordat je al inzicht hebt in de individuele
          meningen, kan je effectief inzetten op de vraagstukken waar men het
          (nog) niet over eens is. Onze ervaring is dat de familie het over 80
          tot 90% van alle vraagstukken eens is. Juist het deel waar men over
          van mening verschilt, is waar je je aandacht aan wilt besteden.<br />
          <br />
          Doordat ons online platform alle noodzakelijke documentatie genereert
          voor het traject, ervaren adviseurs het als een uniforme werkwijze,
          die snelheid en efficiency geeft. De familieleden ervaren een aanpak
          die meer perspectieven biedt, gebruiksvriendelijk, flexibel en
          objectief is.<br /><br />

          <dialog-fam-video />
          Bekijk de animatie
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/famstat/fam_sega2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import dialogFamVideo from "@/components/home/dialogFamVideo";
export default {
  name: "famAseg",
  components: { dialogFamVideo },
};
</script>

<style scoped></style>

<template>
  <v-main>
    <h1>Wellicht nog een video</h1>
    <Footer />
  </v-main>
</template>

<script>
import Footer from "@/components/footer";

export default {
  name: "Profiel",
  components: {
    Footer,
  },
};
</script>
<style scoped></style>

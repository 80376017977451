<template>
  <div id="video">
    <div class="hidden-sm-and-down">
      <video-background
        :src="require('@/assets/video/vid_home.mp4')"
        style="max-height: 1200px; height: 80vh"
        :loop="false"
        :poster="require('@/assets/video/vid_home2.jpg')"
      >
        <v-row justify="center">
          <div>
            <transition
              enter-active-class="animate__animated animate__fadeInDown"
              appear
            >
              <h1
                class="text-h4 text-md-h2 text-lg-h1 text-xl-h1 boven1 secondary--text font-weight-bold"
              >
                wij versterken
              </h1>
            </transition>
          </div>
        </v-row>
        <v-row justify="center">
          <div>
            <transition
              enter-active-class="animate__animated animate__jackInTheBox"
              appear
            >
              <h1
                class="text-h4 text-md-h2 text-lg-h1 text-xl-h1 midden1 primary--text font-weight-bold"
              >
                UW POSITIE
              </h1>
            </transition>
          </div>
        </v-row>
        <v-row justify="center">
          <div>
            <transition
              enter-active-class="animate__animated animate__fadeInUpBig"
              appear
            >
              <h1
                class="text-h4 text-md-h2 text-lg-h1 text-xl-h1 onder1 secondary--text font-weight-bold"
              >
                bij het MKB (familie)bedrijf
              </h1>
            </transition>
          </div>
        </v-row>
      </video-background>
    </div>
    <div class="hidden-md-and-up">
      <video-background
        :src="require('@/assets/video/vid_home.mp4')"
        style="max-height: 600px; height: 25vh"
        :loop="false"
        :poster="require('@/assets/video/vid_home2.jpg')"
      >
        <v-row justify="center">
          <div>
            <transition
              enter-active-class="animate__animated animate__fadeInDown"
              appear
            >
              <h1
                class="text-h5 text-md-h2 text-lg-h1 text-xl-h1 boven1a secondary--text font-weight-bold"
              >
                wij versterken
              </h1>
            </transition>
          </div>
        </v-row>
        <v-row justify="center">
          <div>
            <transition
              enter-active-class="animate__animated animate__jackInTheBox"
              appear
            >
              <h1
                class="text-h5 text-md-h2 text-lg-h1 text-xl-h1 midden1a primary--text font-weight-bold"
              >
                UW POSITIE
              </h1>
            </transition>
          </div>
        </v-row>
        <v-row justify="center">
          <div>
            <transition
              enter-active-class="animate__animated animate__fadeInUpBig"
              appear
            >
              <h1
                class="text-h5 text-md-h2 text-lg-h1 text-xl-h1 onder1a secondary--text font-weight-bold"
              >
                bij het MKB (familie)bedrijf
              </h1>
            </transition>
          </div>
        </v-row>
      </video-background>
    </div>
  </div>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";
export default {
  name: "VideoComponent",

  components: {
    VideoBackground,
  },
};
</script>
<style scoped>
.boven1 {
  animation-duration: 1s;
  animation-delay: 3s;
  padding-top: 15vh;
}
.boven1a {
  animation-duration: 1s;
  animation-delay: 3s;
  padding-top: 5vh;
}
.midden1 {
  animation-duration: 2s;
  animation-delay: 3s;
  padding-top: 5vh;
}
.midden1a {
  animation-duration: 2s;
  animation-delay: 3s;
  padding-top: 2vh;
}

.onder1 {
  animation-duration: 2s;
  animation-delay: 3s;
  padding-top: 5vh;
}
.onder1a {
  animation-duration: 2s;
  animation-delay: 3s;
  padding-top: 2vh;
}
</style>

<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-md-1"></v-col>
    <v-col class="col-12 mb-5 ml-15 col-md-4">
      <v-img
        :src="require('@/assets/white/white_devices.png')"
        width="70%"
        class="mt-5"
      >
      </v-img>
    </v-col>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mb-5 mr-5">
        <v-card-title class="primary--text"> White Label </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Ons doel is om een maximale klantbeleving te creëren. Jouw huisstijl
          voeren wij door in alle Apps, e-mails, documenten, PowerPoints en
          andere uitingen. We kunnen zelfs een verbinding maken met de e-mailbox
          van je adviseurs, zodat je klant rechtstreeks met de adviseur
          communiceert via ons online platform. Je levert ons je logo en
          huisstijl kleuren, zodat wij deze in het online platform kunnen
          verwerken.<br /><br />
          Desgewenst kunnen we de interface voor de gebruikers (je medewerkers)
          in huisstijl omzetten. Zo heb je een volledige beleving van een eigen
          online platform bij zowel je medewerkers als bij je klanten.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "whiteLabel",
};
</script>

<style scoped></style>

<template>
  <v-main>
    <VideoComponentFs />
    <fam-aseg />
    <fam-bseg />
    <fam-cseg />
    <white-label />
    <Footer />
  </v-main>
</template>

<script>
import Footer from "@/components/footer";
import VideoComponentFs from "@/components/video/VideoComponentFs";
import famAseg from "@/components/famstat/famAseg";
import famBseg from "@/components/famstat/famBseg";
import famCseg from "@/components/famstat/famCseg";
import whiteLabel from "@/components/whiteLabel";
export default {
  name: "Familiestatuut",
  components: {
    Footer,
    VideoComponentFs,
    famAseg,
    famBseg,
    famCseg,
    whiteLabel,
  },
};
</script>
<style scoped></style>

<template>
  <v-main>
    <VideoComponentNoodplan />
    <nood-aseg />
    <nood-bseg />
    <nood-cseg />
    <white-label />
    <Footer />
  </v-main>
</template>

<script>
import Footer from "@/components/footer";
import VideoComponentNoodplan from "@/components/video/VideoComponentNoodplan";
import noodAseg from "@/components/noodplan/noodAseg";
import noodBseg from "@/components/noodplan/noodBseg";
import noodCseg from "@/components/noodplan/noodCseg";
import WhiteLabel from "@/components/whiteLabel";
export default {
  name: "Noodplan",
  components: {
    WhiteLabel,
    Footer,
    VideoComponentNoodplan,
    noodAseg,
    noodBseg,
    noodCseg,
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <v-dialog v-model="dialog" width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text color="primary" v-bind="attrs" v-on="on">
          lees verder
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary--text"> Kwetsbaar</v-card-title>

        <v-card-text class="body-1 grey--text text--darken-2">
          Juist in Corona tijd ervaren we hoe kwetsbaar we zijn <br />
          <br />
          Hoeveel ondernemers te kampen hebben gehad met het COVID-19 is niet
          bekend. Maar het is voorstelbaar dat de impact van een ondernemer die
          ernstig ziek is en niet bereikbaar is om leiding te geven aan zijn
          bedrijf zwaar doorweegt.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "newsC",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>

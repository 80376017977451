<template>
  <div class="leftPanel mt-5">
    <v-row class="ml-5 mt-5 mr-5">
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/famstat/fs_full2.jpg')" height="250px">
          </v-img>
          <v-card-title class="primary--text"
            >Familiestatuut volledige versie</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            In de volledige versie bieden we 11 verschillende perspectieven aan
            op het familiebedrijf. Deze versie is zowel voor grote als
            middelgrote familiebedrijven in te zetten.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/famstat/fs_lt.jpg')" height="250px">
          </v-img>
          <v-card-title class="primary--text"
            >Familiestatuut Light</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            Deze versie heeft 5 modules waarin de belangrijkste componenten voor
            een familieopvolging in opgenomen zijn. Deze versie is bedoeld voor
            kleinere familiebedrijven met een minder complexe situatie.
            Bijvoorbeeld een overdracht van vader naar zoon.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/famstat/fs_spec2.jpg')" height="250px">
          </v-img>
          <v-card-title class="primary--text">Special</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Voor groepen met complexe besluitvormingsprocessen bouwen we
            soortgelijke oplossingen. Denk aan het partnerstatuut voor bedrijfs-
            of onroerend goed eigenaren, het Vermogensstatuut voor welvarende
            families en het Beleggingsstatuut voor Verenigingen & Stichtingen.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "famCseg",
};
</script>

<style scoped></style>

<template>
  <v-row no-gutters class="pt-8 mr-5 ml-5">
    <v-col class="col-12 col-md-3 order-3 order-md-1">
      <v-row no-gutters class="mt-3">
        <v-col cols="3" />
        <v-col cols="6">
          <v-img
            :src="
              require(`@/assets/logo_${
                $vuetify.theme.dark ? 'light' : 'dark'
              }.png`)
            "
          />
        </v-col>
        <v-col cols="3" />
      </v-row>
      <v-row no-gutters class="mt-5 mb-15 mt-15">
        <div class="ml-5">
          <div class="text-h5 primary--text">Trusted Accountant<br /></div>
          <div class="body-1 grey--text text--darken-2">
            W. Vrijlandtstraat 8<br />
            3262 GN Oud-Beijerland<br />
            email: info@trustedaccountant.nl<br />
            tel: Arie Heerschap: +31 6 1963 1986
          </div>
        </div>
      </v-row>
    </v-col>
    <v-col class="col-12 col-md-4 order-2 order-md-2">
      <v-card class="transparent">
        <v-card-title class="primary--text">Laatste nieuws</v-card-title>
        <v-card-text>
          <header-a />
          <news-a />
          <header-b />
          <news-b />
          <header-c />
          <news-c />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col class="col12 col-s-5 col-md-5 order-1 order-md-3">
      <v-sheet class="transparent">
        <v-card-title class="primary--text"> Contact</v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy lazy-validation>
            <v-textarea
              outlined
              shaped
              placeholder="Jouw vraag of opmerking"
              v-model="message"
              :counter="200"
              :rules="messageRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="Bedrijf"
              v-model="company_name"
              :counter="150"
              :rules="companyRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="Voornaam"
              v-model="contact_front"
              :counter="50"
              :rules="frontRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="Achternaam"
              v-model="contact_back"
              :counter="150"
              :rules="backRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="e-mail"
              v-model="contact_email"
              :counter="150"
              :rules="emailRules"
              required
            ></v-text-field>
            <v-text-field
              outlined
              shaped
              placeholder="telefoonnummer"
              v-model="contact_tel"
              :counter="15"
            ></v-text-field>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn
                class="mr-8"
                large
                dark
                color="primary"
                :disabled="!valid"
                @click="verstuur"
                >Verstuur</v-btn
              >
            </v-row>
          </v-form>
        </v-card-text>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import headerA from "@/components/news/headerA";
import newsA from "@/components/news/newsA";
import headerB from "@/components/news/headerB";
import newsB from "@/components/news/newsB";
import headerC from "@/components/news/headerC";
import newsC from "@/components/news/newsC";
import axios from "axios";
export default {
  name: "Footer",
  components: { headerA, newsA, headerB, newsB, headerC, newsC },
  data() {
    return {
      valid: true,
      message: null,
      team_id: 1,
      user_id: 2,
      language: "nl",
      company_name: null,
      contact_front: "",
      contact_back: "",
      contact_email: "",
      contact_tel: "",
      messageRules: [
        (v) => !!v || "Veld is verplicht",
        (v) => (v && v.length <= 200) || "Je hebt maximaal 200 karakters",
      ],
      companyRules: [
        (v) => !!v || "Wil je de bedrijfsnaam invullen",
        (v) => (v && v.length <= 150) || "Je hebt maximaal 150 karakters",
      ],
      frontRules: [
        (v) => !!v || "Wil je je voornaam invullen",
        (v) => (v && v.length <= 50) || "Je hebt maximaal 150 karakters",
      ],
      backRules: [
        (v) => !!v || "Wil je je achternaam invullen",
        (v) => (v && v.length <= 150) || "Je hebt maximaal 150 karakters",
      ],
      emailRules: [
        (v) => !!v || "Wil je je e-mailadres invullen",
        (v) => /.+@.+\..+/.test(v) || "Dit lijkt geen geldig e-mailadres",
      ],
      telRules: [
        (v) => (v && v.length <= 15) || "Je hebt maximaal 15 karakters",
      ],
    };
  },
  methods: {
    verstuur() {
      this.$refs.form.validate();
      let data = JSON.stringify({
        message_client: this.message,
        first_name_client: this.contact_front,
        last_name_client: this.contact_back,
        email_client: this.contact_email,
        phone_number_client: this.contact_tel,
        company_name_client: this.company_name,
        language_client: "nl",
      });
      // eslint-disable-next-line no-undef
      axios.post("/websiteContact", data, {
        headers: {},
      });
      this.$swal({
        icon: "success",
        title: "Verzonden",
        text: "Wij hebben je vraag ontvangen, je krijgt direct een bevestiging per e-mail.",
        showConfirmButton: false,
        timer: 1200,
      })
        .then((response) => {
          // eslint-disable-next-line
          console.log(response);
          this.$refs.form.reset();
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.$swal({
            icon: "warning",
            title: "Oops er gaat iets fout",
            text: "Let op er gaat iets niet goed",
            footer:
              "Probeer het aub nogmaals mocht je problemen blijven ondervinden wil je dan contact met ons opnemen?",
          });
        });
    },
  },
};
</script>

<style scoped></style>

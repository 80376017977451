<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5 mb-5">
        <v-card-title class="primary--text"> Vermogensregie </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Vermogensregie gaat over het volledige vermogen van je klant.
          Zakelijk, semi-zakelijk en privé. Hoe maak je inzichtelijk wat de
          verhouding tussen rendement en risico is over het volledige vermogen?
          Hoe relateer je de vermogenscomponenten aan de belangrijke wensen en
          doelen? Hoe adviseer en begeleid je je klant bij de verschillende
          vraagstukken zoals governance, investeren, financieren, beleggen, etc.
          gedurende de fases van vermogensgeneratie, vermogensbehoud en
          vermogensoverdracht.<br />
          <br />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_prodverm2.jpg')"
        width="100%"
        class="mt-5"
        height="40vh"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "vermAseg",
};
</script>

<style scoped></style>

<template>
  <div class="mt-5">
    <v-row class="ml-5 mt-5 mr-5">
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/vermregie/verm_1a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Netto vermogen</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Vrijwel elke MKB ondernemer vraagt zich op eng moment af "Wat kan ik
            mij veroorloven". Deze behoefte aan financiële onafhankelijkheid
            gekoppeld aan de wensen en doelen is zeer goed in te vullen door de
            accountant of fiscalist. Wij ontwikkelen momenteel een
            inventarisatiemodule, die het vermogen in Box I, II en III vertaalt
            naar een netto vermogen. Door verschillende analyses te maken, wordt
            inzichtelijk hoe het vermogen is gestructureerd en welke
            rendements-/risicoverhouding van toepassing is.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/vermregie/verm_2a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Bordspel</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Het begeleiden van de gedachtenvorming over wat werkelijk belangrijk
            is om financieel in te vullen, is een complex proces. Wij
            ontwikkelden hiervoor een interactieve methode die ondernemers en
            hun levenspartners uitdaagt om verder te onderzoeken en ontdekken
            wat zij aan levensdoelen hebben. Wij noemen dit het Bordspel,
            waarbij geleidelijk duidelijk wordt welke doelen, met welke
            prioriteit en horizon, de klant werkelijk heeft.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/vermregie/verm_3a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Werkkapitaalanalyse</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Wij ontwikkelen momenteel de Cash Conversion Cycle methodiek voor
            accountants. Klanten bewust maken van het aantal dagen dat het
            vermogen vast zit in het primaire proces, is de eerste stap naar een
            effectiever werkkapitaalbeheer en belangrijke kostenbesparingen.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "vermBseg",
};
</script>

<style scoped></style>

<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          Personal Landing Page
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Een Personal Landing Page is een webpagina en kan gekoppeld worden aan
          de corporate website of social mediacampagne. De inhoud bestaat uit
          teksten, visuals (animaties of video's) en een aantal vragen die de
          bezoeker kan beantwoorden. Het is een effectieve en zeer
          gebruiksvriendelijke wijze om kennis te delen en toepasbaar te maken
          op de situatie van de bezoeker.<br /><br />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6">
      <v-img
        :src="require('@/assets/pcm/pcm_segc2a.jpg')"
        width="100%"
        class="mt-5"
        height="50vh"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "pcmCseg",
  components: {},
  methods: {
    toLp() {
      window.open("https://trustedaccountant.nl", "_blank");
    },
  },
};
</script>

<style scoped></style>

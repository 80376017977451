<template>
  <div class="leftPanel mt-5">
    <v-row>
      <v-card width="100%" class="ml-5 mr-5 mt-5">
        <v-card-title class="justify-center primary--text">
          Producten
        </v-card-title>

        <v-card-text class="body-1 grey--text text--darken-2">
          <br /><br />
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="ml-5 mt-5 mr-5">
      <prodFam></prodFam>
      <prodNood></prodNood>
      <prod-verm></prod-verm>
    </v-row>
  </div>
</template>

<script>
import prodFam from "@/components/home/prodFam";
import prodNood from "@/components/home/prodNood";
import prodVerm from "@/components/home/prodVerm";
export default {
  name: "homeCseg",
  components: { prodFam, prodNood, prodVerm },
};
</script>

<style scoped></style>

<template>
  <v-row justify="center" no-gutters>
    <nood-ken-a />
    <nood-ken-b />
    <nood-ken-c />
    <nood-ken-d />
    <nood-ken-e />
    <nood-ken-f />
  </v-row>
</template>

<script>
import noodKenA from "@/components/noodplan/noodKenA";
import noodKenB from "@/components/noodplan/noodKenB";
import noodKenC from "@/components/noodplan/noodKenC";
import noodKenD from "@/components/noodplan/noodKenD";
import noodKenE from "@/components/noodplan/noodKenE";
import noodKenF from "@/components/noodplan/noodKenF";

export default {
  name: "noodBseg",
  components: {
    noodKenA,
    noodKenB,
    noodKenC,
    noodKenD,
    noodKenE,
    noodKenF,
  },
};
</script>

<style scoped></style>

<template>
  <v-row class="leftPanel" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          The Future of Advice
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Jouw klant verwacht dat je in staat bent om 24/7 op een persoonlijke
          en effectieve wijze informatie te delen. Via het device en het kanaal
          dat jouw klant kiest. Waarbij de klant in staat wil zijn om in eigen
          tijd en tempo dit te doorgronden en te reageren. De klant als
          regisseur van het 'gesprek'.<br /><br />
          In de nabije toekomst kunnen ‘robo-advisors’ en chatrobots een deel
          van de kennisdeling van je overnemen. Zover is het nog niet, maar
          duidelijk is dat je wordt uitgedaagd om nieuwe methoden te vinden om
          tegemoet te komen aan de communicatiebehoeften van jouw klant. Onze
          technologie helpt je om jouw kennis te delen met jouw klanten, de
          urgentie en prioriteit met betrekking tot vraagstukken bij jouw klant
          te verkennen en daardoor jouw adviesprocessen te versnellen en te
          optimaliseren.
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_robot2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "homeAseg",
};
</script>

<style scoped></style>

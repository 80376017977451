<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="primary">
                <v-icon> mdi-binoculars </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center secondary--text"
              >Toekomst van het bedrijf</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  Het scenario van noodopvolging dwingt de ondernemer na te
                  denken over deze en andere scenario’s. Heeft het bedrijf
                  voldoende bestaansrecht en is het aantrekkelijk voor kopers?
                  Als sprake is van opvolging vanuit de familie, is de opvolger
                  dan voldoende geschikt? Met het Noodplan maak je deze
                  scenario's bespreekbaar.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "noodKenE",
};
</script>

<style scoped></style>

<template>
  <v-row class="mt-0" no-gutters>
    <v-col class="hidden-sm-and-down col-md-6 mb-5">
      <v-img
        :src="require('@/assets/pcm/pcm_prod2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>

    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5 mb-5">
        <v-card-title class="primary--text">Voordelen</v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          <v-row no-gutters>
            <v-col class="col-2">
              <v-btn
                class="mx-2 mb-3"
                elevation="15"
                fab
                dark
                large
                color="secondary"
              >
                <v-icon> mdi-ear-hearing </v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-5 mt-5"> Snel kennis delen </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-2">
              <v-btn
                class="mx-2 mb-3"
                elevation="15"
                fab
                dark
                large
                color="secondary"
              >
                <v-icon> mdi-speedometer </v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-5 mt-5">
              Kennisoverdracht in enkele seconden
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-2">
              <v-btn
                class="mx-2 mb-3"
                elevation="15"
                fab
                dark
                large
                color="secondary"
              >
                <v-icon> mdi-head-heart </v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-5 mt-5">
              Input van de klant bepaalt verloop adviesproces
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-2">
              <v-btn
                class="mx-2 mb-3"
                elevation="15"
                fab
                dark
                large
                color="secondary"
              >
                <v-icon> mdi-handshake </v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-5 mt-5">
              Adviseur én klant goed voorbereid aan tafel
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-2">
              <v-btn
                class="mx-2 mb-3"
                elevation="15"
                fab
                dark
                large
                color="secondary"
              >
                <v-icon> mdi-microsoft-powerpoint </v-icon>>
              </v-btn>
            </v-col>
            <v-col class="ml-5 mt-5">
              Snelle verwerking van rapporten en PowerPoints
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-2">
              <v-btn
                class="mx-2 mb-3"
                elevation="15"
                fab
                dark
                large
                color="secondary"
              >
                <v-icon> mdi-clipboard-flow </v-icon>>
              </v-btn>
            </v-col>
            <v-col class="ml-5 mt-5">
              Inzicht in activiteiten en successen
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-2">
              <v-btn
                class="mx-2 mb-3"
                elevation="15"
                fab
                dark
                large
                color="secondary"
              >
                <v-icon> mdi-ruler-square </v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-5 mt-5"> Compliant door uniforme werkwijze </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "pcmDseg",
};
</script>

<style scoped></style>

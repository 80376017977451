<template>
  <v-row no-gutters>
    <v-col class="col-121 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_lamp2a.jpg')"
        width="100%"
        class="mt-5"
        height="70vh"
      >
      </v-img>
    </v-col>
    <v-col class="col-12 col-md-6 mt-5">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text"
          >Jullie inhoud, onze technologie</v-card-title
        >
        <v-card-text class="body-1 grey--text text--darken-2">
          Heeft onze technologie en aanpak je geïnspireerd? Heb je zelf een
          thema, vraagstuk of product dat je snel en effectief onder de aandacht
          van je klanten wil brengen?
          <br /><br />
          Hoe werkt het? Gezamenlijk inventariseren we hoe jullie inhoud met
          onze technologie kan worden vertaald naar persoonlijke, praktische en
          toepasbare kennis voor jouw klanten. Welke doelgroepen wil je bereiken
          en via welk kanaal. Welke methode het beste past en welke tooling
          nodig is voor de adviseur om de adviesgesprekken te voeren
          (PowerPoints, Word-documenten, etc.).
          <br /><br />
          Op basis van deze inventarisatie nemen we de stap om de inhoud
          concreet te maken door middel van animaties, teksten, vragen &
          antwoorden, etc. Als deze inhoud compleet is, programmeren wij dit in
          onze testomgeving. Afhankelijk van de complexiteit en onze planning
          kan dit in een paar dagen gereed zijn. Vervolgens krijg je de
          gelegenheid om te testen. Is de test akkoord, dan implementeren we
          direct in je eigen online platform.
        </v-card-text>
        <v-card-title class="primary--text">Actualiteit</v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Door onze pragmatische aanpak, kunnen we korte ontwikkeltijden
          hanteren. Dat maakt onze aanpak zeer geschikt om actuele thema's of
          vraagstukken snel onder de aandacht van uw klanten te brengen.
          Bijvoorbeeld door een social media campagne die verwijst naar een
          Personal Landingpage die wij voor je neerzetten en beheren.
          <br /><br />
          Meer weten? Neem contact met ons op voor een eerste kennismaking.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "homeEseg",
};
</script>

<style scoped></style>

<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small text color="primary" v-bind="attrs" v-on="on">
        Algemene voorwaarden
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon color="secondary" @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="primary" class="btnAn" @click="closeWin">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title class="primary--text">
        Algemene voorwaarden <br /><br />
        INLEIDING
      </v-card-title>

      <v-card-text class="body-1 grey--text text--darken-2">
        De service die Trustedaccountant.nl biedt is voor alle klanten. Elke dag
        is Trustedaccountant.nl bezig om haar service te verbeteren. Het kan
        goed zijn dat Trustedaccountant.nl aanpassingen doet in haar service om
        de samenwerking met de klanten nog beter te laten verlopen. De
        verschillende onderwerpen die op de samenwerking van toepassing zijn
        staan hieronder in losse hoofdstukken beschreven. Gezamenlijk vormen
        deze hoofdstukken de volledige Service Level Agreement,Algemene
        Voorwaarden en Verwerkersovereenkomst.
      </v-card-text>
      <v-card-title class="primary--text"> Over SLA/AV </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Deze voorwaarden zijn van toepassing op alle overeenkomsten die Laapheer
        BV (Trustedaccountant.nl) sluit. Trustedaccountant.nl streeft naar
        transparante en eenvoudige voorwaarden, zodat niet alleen een jurist,
        maar iedereen ze kan begrijpen. Ze zijn bij het sluiten van de
        overeenkomst ter hand gesteld, dan wel als direct aanklikbare hyperlink
        als PDF beschikbaar gemaakt. Als er voorwaarden onduidelijk zijn, dan
        hoort Trustedaccountant.nl dat graag. Individuele afspraken die in de
        overeengekomen productovereenkomst staan, zijn ook van toepassing en
        zijn leidend als ze strijdig zijn met de SLA/AV.
      </v-card-text>
      <v-card-title class="primary--text">
        Toepasselijk recht en geschillen.
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Op de SLA/AV is het Nederlands recht van toepassing. Het Weens
        Koopverdrag, dat eigen regels kent voor internationale
        koopovereenkomsten, is uitgesloten. Mochten er geschillen ontstaan
        tussen klant en Trustedaccountant.nl, dan zullen die exclusief worden
        voorgelegd aan een bevoegde rechter in Nederland. Wanneer één of meer
        bepalingen nietig of vernietigbaar zijn, blijven de overige bepalingen
        van kracht.
      </v-card-text>
      <v-card-title class="primary--text"> Wijzigingen </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl werkt iedere dag aan de ontwikkeling van haar
        programmatuur die het werken ermee vergemakkelijken. De SLA/AV kunnen
        hierin ook mee veranderen, waarbij het uitgangspunt is dat die
        wijzigingen de kwaliteit van de dienstverlening van Trustedaccountant.nl
        ten goede komen. <br />Wijzigingen zullen altijd kenbaar gemaakt worden
        per e-mail, waarbij grote wijzigingen ook in een speciale nieuwsbrief
        zullen worden vermeld. Indien de Trustedaccountant.nl programmatuur en
        Trustedaccountant.nl diensten gebruikt blijven worden, is de meest
        recente Algemene Voorwaarden en SLA’ van toepassing. Er kan eventueel
        bezwaar aangetekend worden tegen de wijzigingen door een
        ‘verbetersuggestie’ in te sturen. We zullen dan beoordelen of de
        wijziging kan leiden tot aanpassing ervan. In het uiterste geval bestaat
        de mogelijkheid om na de wijziging de overeenkomst te beëindigen,
        waarbij de oude voorwaarden nog 2 maanden geldig blijven. Overeenkomst
        De overeenkomst wordt aangegaan voor onbepaalde tijd met een minimum van
        één jaar, tenzij anders is overeengekomen. Met het aangaan van een
        overeenkomst ontvangt de klant een licentie voor het gebruik van de
        Trustedaccountant.nl programmatuur. Deze wordt via een abonnementsvorm
        gefactureerd. Licentie In de licentie zijn de afgenomen
        productonderdelen (o.a. gebruikers, medewerkers, en omgevingen) met
        bijbehorende aantallen en begin- en einddatum versleuteld. De licentie
        mag alleen gebruikt worden voor de eigen onderneming(en) van de klant.
        De omvang van de licentie wordt, tenzij anders overeengekomen, afgestemd
        op het aantal medewerkers van de organisatie. Medewerkers kunnen
        vervolgens gebruikers-, beheerders- of een andere rol hebben.
      </v-card-text>
      <v-card-title class="primary--text"> Prijsbepaling </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl werkt voor de prijsbepaling van de licentie onder
        andere met staffels op basis van afnamehoeveelheden en prijzen per
        sector/branche. Bij het verhogen of verlagen van de licentieaantallen
        kan de prijsstaffel muteren. Een specificatie van de aangeboden
        producten, prijzen en staffels is terug te vinden in het onderdeel
        ‘Licenties & Prijzen’ in het contract. Trustedaccountant.nl voert ten
        aanzien van haar prijzen een markt- en prijsindex conform beleid.
        Trustedaccountant.nl kan de prijzen jaarlijks indexeren, waarbij er
        rekening gehouden wordt met de jaarmutatie Consumentenprijsindexcijfer
        van afgelopen oktober. Bij indexering stuurt Trustedaccountant.nl voor 1
        december een bericht aan de klanten waarin het percentage wordt vermeld.
        De indexering gaat in vanaf de eerste factuur in het volgende
        kalenderjaar.
      </v-card-text>
      <v-card-title class="primary--text"> Facturatie </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl factureert:<br />
        ·Licenties en beheer-/onderhoudskosten: jaarlijks vooraf;<br />
        ·Consultancy: wekelijks, achteraf of via afgesproken termijnen;<br />
        ·Opleidingen: bij aanvang opleiding; ·Overige kosten zoals
        implementatie- of huisstijlkosten: éénmalig voorafgaand aan start
        implementatie.<br />
        Op de abonnementsfactuur staan de actuele aantallen per product vermeld.
        Alle facturen worden per e-mail in PDF-bestand verstuurd aan.<br />
        De betalingstermijn is 30 dagen, tenzij anders is overeengekomen. Zodra
        aan alle financiële verplichtingen is voldaan, wordt de licentie
        automatisch met één jaar verlengd.
      </v-card-text>
      <v-card-title class="primary--text">
        Mutatie van het abonnement
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Mutaties in de licentie kunnen per e-mail aan
        administratie@trustedaccountant.nl worden aangevraagd.
      </v-card-text>
      <v-card-title class="primary--text"> Productonderdelen </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Een uitbreiding van de licentie kan per direct doorgevoerd worden. Een
        opzegging van het productonderdeel kan na minimaal één jaar volledige
        facturatie van de licentie doorgevoerd worden. Aantallen Een verhoging
        van de aantallen (medewerkers, omgevingen e.d.) kan per direct
        doorgevoerd worden. Een verlaging van de aantallen kan alleen na één
        jaar volledige facturatie van de licentie. Een verhoging én een
        verlaging binnen dezelfde maand is niet mogelijk.
      </v-card-text>
      <v-card-title class="primary--text">
        Beëindiging van de overeenkomst
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Het beëindigen van de overeenkomst kan per e-mail aan
        administratie@trustedaccountant.nl verstuurd worden. Beëindiging door
        klant Voor het stopzetten van de licentie geldt een opzegtermijn van 2
        maanden voor aanvang het een nieuw contractjaar. De toegang tot de
        programmatuur en bijbehorende data blijft dan mogelijk tot het einde van
        het lopende contractjaar. Na de beëindiging van de licentie is de
        toegang tot de programmatuur en bijbehorende data niet meer mogelijk.
      </v-card-text>
      <v-card-title class="primary--text">
        Beëindiging door Trustedaccountant.nl
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        De opzegtermijn voor Trustedaccountant.nl bedraagt 12 maanden.
        Trustedaccountant.nl heeft het recht de overeenkomst per direct te
        beëindigen indien de klant zijn verplichtingen niet nakomt (na daartoe
        in gebreke te zijn gesteld), surseance van betaling heeft aangevraagd of
        het faillissement is aangevraagd. Procedure klantdata na beëindiging Na
        de einddatum van de licentie worden alle data door Trustedaccountant.nl
        verwijderd.Trustedaccountant.nl werkt niet mee aan datamigratie, behalve
        als sprake is van het inlezen van een kopie in een andere licentie van
        Trustedaccountant.nl. Indien de data in een nieuw abonnement ingelezen
        moet worden, geldt voor het nieuwe abonnement ook weer het maandelijkse
        gebruiksrecht en de opzegtermijn van een nieuw abonnement. De hoogte van
        het nieuwe gebruiksrecht kan niet lager zijn dan het originele
        abonnement.
      </v-card-text>
      <v-card-title class="primary--text"> Aansprakelijkheid </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl garandeert dat “de Backoffice” voldoet aan alle
        specificaties die zij opgeeft. In geval van fouten zal
        Trustedaccountant.nl deze altijd zo snel mogelijk herstellen.
        Trustedaccountant.nl besteedt grote zorg aan een juiste werking van “de
        Backoffice” en een correcte uitvoering van haar dienstverlening. Ondanks
        deze inspanningen kunnen er dingen verkeerd gaan, die voor de klant tot
        schade leiden. Trustedaccountant.nl streeft daarbij in overleg met de
        klant steeds naar een passende oplossing. De aansprakelijkheid van
        Laapheer B.V. is beperkt tot € 250.000,- per gebeurtenis of serie van
        gebeurtenissen met een gemeenschappelijke oorzaak en zal samengesteld
        nooit hoger zijn dan € 250.000,- per kalenderjaar. Trustedaccountant.nl
        kan op de aansprakelijkheidsbeperkingen geen beroep doen als er sprake
        is van opzet dan wel bewuste roekeloosheid in het handelen van haarzelf,
        van haar medewerkers of door haar ingeschakelde derden.
        Trustedaccountant.nl sluit haar aansprakelijkheid uit voor iedere vorm
        van gevolgschade, zoals gederfde omzet, gederfde winst en gemiste
        kansen. De aansprakelijkheid van Trustedaccountant.nl is ook uitgesloten
        als de klant of door de klant ingeschakelde derden wijzigingen in
        producten van Trustedaccountant.nl hebben aangebracht, wat niet is
        toegestaan. Trustedaccountant.nl en de klant zijn niet aansprakelijk ten
        opzichte van elkaar als er sprake is van overmacht.
      </v-card-text>
      <v-card-title class="primary--text"> Melden </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl verlangt van de klant dat deze zo spoedig mogelijk
        een klacht of een claim meldt bij Trustedaccountant.nl. Niet alleen kan
        Trustedaccountant.nl dan direct met de klant werken aan een oplossing,
        maar ook dient Trustedaccountant.nl een claim te melden bij haar
        verzekeraar. Overigens blijft overeind dat Trustedaccountant.nl, los van
        deze klacht en/of claim, streeft, in overleg met de klant, naar een
        passende oplossing.
      </v-card-text>
      <v-card-title class="primary--text"> Leveringstermijnen </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Wanneer Trustedaccountant.nl een (op)levertermijn niet haalt, zal de
        klant Trustedaccountant.nl eerst in gebreke stellen en
        Trustedaccountant.nl alsnog een redelijke termijn geven om haar
        verplichtingen na te komen.
      </v-card-text>
      <v-card-title class="primary--text">
        Verwerkersovereenkomst
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl verwerkt onder andere persoonsgegevens voor en in
        opdracht van de klant omdat de klant een software gebruikersovereenkomst
        met Trustedaccountant.nl heeft. Trustedaccountant.nl en de klant zijn
        daarom verplicht volgens de Algemene Verordening Gegevensbescherming
        (AVG) om een Verwerkersovereenkomst te sluiten. Omdat
        Trustedaccountant.nl een standaard applicatie (“de Backoffice”) met de
        daarbij behorende standaard dienstverlening levert, heeft
        Trustedaccountant.nl de verwerkingsovereenkomst opgenomen in de Algemene
        Voorwaarden en SLA. Trustedaccountant.nl is in deze de 'verwerker' en de
        klant de 'verwerkingsverantwoordelijke'. Trustedaccountant.nl en de
        klant verplichten zich over en weer om de Algemene Verordening
        Gegevensbescherming (AVG) na te leven. Voor de definities van begrippen
        wordt aangesloten bij de AVG. Trustedaccountant.nl zal de
        persoonsgegevens alleen verwerken voor en in opdracht van de klant en om
        uitvoering te geven aan de overeenkomst. Instructies verwerking De
        verwerking bestaat uit het beschikbaar stellen van de
        Trustedaccountant.nl applicaties met de door de klant ingevoerde en
        gegenereerde data. Trustedaccountant.nl zal geen gegevens toevoegen,
        aanpassen of verwijderen zonder dat de klant daar schriftelijke
        instructie voor gegeven heeft. Die instructie kan via een verzoek worden
        gegeven. Binnen de applicaties, die Trustedaccountant.nl beschikbaar
        stelt, zijn verschillende soorten persoonsgegevens vast te leggen.
        Trustedaccountant.nl is zich ervan bewust dat de klant al deze, en
        eventueel nog zelf aan te maken persoonsgegevens of categorieën, kan
        invoeren en dat Trustedaccountant.nl deze dan zal verwerken. De klant is
        zelf verantwoordelijk voor de beoordeling of het doel en aard van de
        verwerking past bij de dienstverlening die Trustedaccountant.nl doet.
        Trustedaccountant.nl verzamelt geanonimiseerde gegevens over het gebruik
        van haar producten en diensten. Deze gegevens ondersteunen
        Trustedaccountant.nl om inzicht te krijgen of, hoe en hoe vaak bepaalde
        onderdelen van het product gebruikt worden. De geanonimiseerde gegevens
        zullen uitsluitend gebruikt worden om producten en dienstverlening te
        verbeteren. Trustedaccountant.nl zal de verzamelde
        gebruikersstatistieken nooit gebruiken voor commerciële doeleinden of
        aanbieden aan derde partijen.
      </v-card-text>
      <v-card-title class="primary--text"> Geheimhoudingsplicht </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl is zich bewust dat de informatie die de klant met
        Trustedaccountant.nl deelt en opslaat binnen “de Backoffice”, een geheim
        en bedrijfsgevoelig karakter heeft. Alle
        Trustedaccountant.nl-medewerkers zijn via hun arbeidsovereenkomst
        verplicht eventuele gegevens van de klant strikt geheim te houden.
        Medewerkers met toegang tot klantgegevens Alleen systeembeheerders van
        “de Backoffice” hebben volledige toegang tot de klantgegevens voor: ·Het
        plaatsen van een nieuwe versie; ·Het doorvoeren van patches en hotfixes;
        ·Het maken van een back-up; ·Het verplaatsen van gegevens binnen “de
        Backoffice”. Consultants, Supportmedewerkers en andere
        Trustedaccountant.nl medewerkers hebben alleen toegang tot de
        klantgegevens indien zij toestemming daarvoor hebben ontvangen van de
        klant en voor zolang zij toestemming hebben van de klant. Beveiliging
        Trustedaccountant.nl neemt blijvend passende technische en
        organisatorische maatregelen om de persoonsgegevens van de klant te
        beveiligen tegen verlies of enige vorm van onrechtmatige verwerking.
        Deze maatregelen worden aangemerkt als een passend beveiligingsniveau in
        de zin van de AVG. De klant is gerechtigd om in overleg met
        Trustedaccountant.nl tijdens de looptijd van de overeenkomst door een
        onafhankelijke deskundige de naleving hiervan te controleren,
        bijvoorbeeld door middel van het uitvoeren van een audit. De klant zal
        alle kosten in verband met deze controle dragen. Trustedaccountant.nl is
        aansprakelijk voor schade in het kader van persoonsgegevens door
        handelen of nalaten van de subverwerker waarbij de
        aansprakelijkheidsbeperking uit het hoofdstuk Aansprakelijkheid geldt.
        De toepasselijke aansprakelijkheidsbeperking geldt niet indien er bij de
        subverwerker sprake is van grove nalatigheid of opzettelijk wangedrag.
        Trustedaccountant.nl is ook niet aansprakelijk in geval van overmacht
        (zoals gedefinieerd in het hoofdstuk Aansprakelijkheid) bij haarzelf of
        aan de kant van de subverwerker. Indien de Autoriteit Persoonsgegevens
        aan de verwerkersverantwoordelijke een bindende aanwijzing zal geven
        dient de klant Trustedaccountant.nl direct op de hoogte stellen van deze
        bindende aanwijzing. Trustedaccountant.nl zal er alles aan doen wat in
        redelijkheid van haar verwacht kan worden om de naleving mogelijk te
        maken. Als Trustedaccountant.nl niet doet wat in redelijkheid van haar
        gevraagd kan worden waardoor er een boete volgt, of als de Autoriteit
        Persoonsgegevens direct een boete oplegt omdat sprake is van opzet of
        ernstige verwijtbare nalatigheid aan de kant van Trustedaccountant.nl,
        dan geldt de toepasselijke aansprakelijkheidsbeperking als hiervoor
        genoemd in het hoofdstuk Aansprakelijkheid niet. Subverwerkers
        Trustedaccountant.nl verwerkt de klantdata in datacenters van Digital
        Ocean en deze is hiermee subverwerker. De datacenters waar
        Trustedaccountant.nl gebruik van maakt bevinden zich uitsluitend in
        Nederland en vallen onder Nederlandse wet- en regelgeving en voldoen aan
        de strenge Nederlandse en Europese wetgeving met betrekking tot logische
        en fysieke toegangsbeveiliging en continuïteit. De datacenters zijn
        minimaal ISO 27001 gecertificeerd. De (persoons)gegevens worden door
        Trustedaccountant.nl en subverwerker uitsluitend verwerkt binnen de
        Europese Economische ruimte. Trustedaccountant.nl heeft aan de
        subverwerker(s) dezelfde verplichtingen opgelegd als die voor haarzelf
        gelden. Trustedaccountant.nl zal geen nieuwe subverwerkers gegevens
        laten verwerken zonder de klant daarover tijdig te informeren. De klant
        kan bezwaar maken bij Trustedaccountant.nl tegen de subverwerker. Mocht
        Trustedaccountant.nl toch gegevens willen laten verwerken door de nieuwe
        subverwerker, heeft de klant de mogelijkheid om de overeenkomst te
        beëindigen. Privacyrechten Trustedaccountant.nl heeft geen zeggenschap
        over de persoonsgegevens die door de klant beschikbaar worden gesteld.
        Zonder noodzaak, gezien de aard van de door de klant verstrekte
        opdracht, expliciete toestemming van de klant of wettelijke verplichting
        zal Trustedaccountant.nl de gegevens niet aan derden verstrekken of voor
        andere doeleinden verwerken, dan voor de overeengekomen doeleinden. De
        klant garandeert dat de persoonsgegevens verwerkt mogen worden op basis
        van een in de AVG genoemde grondslag. Trustedaccountant.nl zal wel,
        indien een verzoek gedaan wordt door de Stichting Autoriteit Financiële
        Markten, De Europese Centrale Bank of De Nederlandsche Bank N.V. op
        grond van de uitvoering van hun taak uit hoofde van de Wft, of op grond
        van andere wet- en regelgeving, alle mogelijke informatie beschikbaar
        stellen aan de betreffende organisatie. Tevens verplicht
        Trustedaccountant.nl de subverwerker, zoals hierboven benoemd, eveneens
        te voldoen aan een dergelijk verzoek van deze toezichthouders.
      </v-card-text>
      <v-card-title class="primary--text"> Betrokkenen </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        De klant is verantwoordelijk voor de ingevoerde gegevens van de
        betrokkenen en daarbij nooit op verzoeken van betrokkenen ingaan en
        altijd verwijzen naar de verantwoordelijke. Trustedaccountant.nl zal,
        voor zover dat binnen de applicatie mogelijk is, haar medewerking
        verlenen aan de klant zodat deze kan voldoen aan zijn wettelijke
        verplichtingen in het geval dat een betrokkene haar rechten uitoefent op
        grond van de AVG of andere toepasselijke regelgeving betreffende de
        verwerking van persoonsgegevens.
      </v-card-text>
      <v-card-title class="primary--text"> Meldplicht datalekken </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        De AVG vereist dat eventuele datalekken gemeld worden aan de Autoriteit
        Persoonsgegevens door de verwerkingsverantwoordelijke van de data.
        Trustedaccountant.nl zal daarom zelf geen meldingen doen bij de
        Autoriteit Persoonsgegevens. Uiteraard zal Trustedaccountant.nl de klant
        juist, tijdig en volledig informeren over relevante incidenten, zodat de
        klant als verwerkingsverantwoordelijke aan zijn wettelijke
        verplichtingen kan voldoen. De Beleidsregels meldplicht datalekken van
        de Autoriteit Persoonsgegevens geven hierover meer informatie. Indien de
        klant een (voorlopige) melding verricht bij de Autoriteit
        Persoonsgegevens en/of de betrokkene(n) over een datalek bij
        Trustedaccountant.nl, zonder dat de klant Trustedaccountant.nl hierover
        geïnformeerd heeft, dan is de klant aansprakelijk voor door
        Trustedaccountant.nl geleden schade en kosten van deze melding. Indien
        blijkt dat er geen sprake is van een datalek bij Trustedaccountant.nl,
        is de klant verplicht de melding direct in te trekken.
      </v-card-text>
      <v-card-title class="primary--text"> Bepaling datalek </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Voor het bepalen van een inbreuk in verband met persoonsgegevens,
        gebruikt Trustedaccountant.nl de AVG en de Beleidsregels meldplicht
        datalekken als leidraad.
      </v-card-text>
      <v-card-title class="primary--text"> Melding aan de klant </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Indien blijkt dat bij Trustedaccountant.nl sprake is van een
        beveiligingsincident of datalek zal Trustedaccountant.nl de klant
        daarover zo spoedig mogelijk informeren nadat Trustedaccountant.nl
        bekend is geworden met het datalek. Om dit te realiseren zorgt
        Trustedaccountant.nl ervoor dat al haar medewerkers in staat zijn en
        blijven om een datalek te constateren en verwacht Trustedaccountant.nl
        van haar opdrachtnemers dat zij Trustedaccountant.nl in staat stelt om
        hieraan te kunnen voldoen. Voor de duidelijkheid: als er een datalek is
        bij een subverwerker van Trustedaccountant.nl, dan meldt
        Trustedaccountant.nl dit uiteraard ook. Trustedaccountant.nl is het
        contactpunt voor de klant. De klant hoeft geen contact op te nemen met
        de subverwerkers van Trustedaccountant.nl Informeren klant
        (contactpersoon instellen) In eerste instantie zal Trustedaccountant.nl
        de contactpersoon van het abonnement informeren over een datalek. Mocht
        deze contactpersoon niet (meer) de juiste zijn, dan kan dat aangepast
        worden door een e-mail te versturen naar
        administratie@trustedaccountant.nl.
      </v-card-text>
      <v-card-title class="primary--text">
        Informatie verstrekken
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl probeert de klant direct alle informatie te
        verstrekken die de klant nodig heeft om een eventuele melding bij de
        Autoriteit Persoonsgegevens en/of de betrokkene(n) te verrichten.
      </v-card-text>
      <v-card-title class="primary--text">
        Termijn van informeren
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        De AVG geeft aan dat er ‘onverwijld’ gemeld moet worden. Dit is volgens
        de Autoriteit Persoonsgegevens zonder onnodige vertraging en zo mogelijk
        niet later dan 72 uur na ontdekking ervan door de verantwoordelijke.
        Indien er een beveiligingsincident optreedt, zal Trustedaccountant.nl de
        klant zo snel mogelijk, maar uiterlijk binnen 24 uur na het ontdekken
        door Trustedaccountant.nl ervan, informeren. De klant zal zelf de
        beoordeling moeten maken of het beveiligingsincident valt onder de term
        ‘datalek’ en of er melding aan de Autoriteit Persoonsgegevens gedaan zal
        moeten worden. De klant heeft hiervoor 72 uur, nadat de klant hiervan op
        de hoogte is gesteld, de tijd. Voortgang en maatregelen
        Trustedaccountant.nl zal de klant op de hoogte houden over de voortgang
        en de maatregelen die getroffen worden. Trustedaccountant.nl maakt
        hierover afspraken met de primaire contactpersoon bij de initiële
        melding. In ieder geval houdt Trustedaccountant.nl de klant op de hoogte
        in geval van een wijziging van de situatie, het bekend worden van nadere
        informatie en over de maatregelen die getroffen worden.
        Trustedaccountant.nl registreert alle security incidenten en handelt
        deze volgens een vaste procedure (workflow) af. De registratie en
        afhandeling van security incidenten wordt getoetst met een audit in het
        kader van de ISO27001 certificering.
      </v-card-text>
      <v-card-title class="primary--text"> Gegevens verwijderen </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl zal, na afloop van de overeenkomst, alle klant
        gegevens verwijderen zoals beschreven staat bij ‘Beëindiging van de
        overeenkomst’. Mocht de klant eerder de gegevens verwijderd willen
        hebben, dan kan daarvoor een verzoek worden ingediend.
        Trustedaccountant.nl verplicht zich daar gehoor aan te geven.
        Uitzondering hierop zijn de backups deze worden niet geschoond in het
        kader van het vergeet mij protocol. Dienstverlening Trustedaccountant.nl
        ondersteunt de klant bij het implementeren van “de Backoffice” met
        consultancy. Door opleidingen te volgen kan de klant “de Backoffice”
        optimaal benutten. Consultancy Trustedaccountant.nl benoemt voor de
        begeleiding van de implementatie een projectleider. Deze projectleider
        stelt samen met de klant een projectplan op met o.a. de planning,
        doelstellingen, scope en verantwoordelijkheden. Elk project wordt
        gemonitord tot en met de oplevering binnen het voorgestelde budget op de
        offerte. De projectleider zal een eventuele overschrijding direct
        signaleren en afstemmen. De klant zorgt ervoor dat er vanuit de kant van
        de klant een projectleider aangesteld wordt om samen met de
        Trustedaccountant.nl projectleider te werken aan het succes van het
        implementatietraject. Opleidingen Om goed met “de Backoffice” te kunnen
        werken is het noodzakelijk dat de gebruikers opleidingen volgen. Met
        deze kennis kan de klant zelf “de Backoffice” optimaal benutten. “De
        Backoffice” “De Backoffice” is betrouwbaar, veilig en snel.
        Trustedaccountant.nl werkt samen met externe partijen voor het
        beschikbaar stellen van Trustedaccountant.nl Online. Daarnaast werkt
        Trustedaccountant.nl met professionele partijen om de beveiliging te
        waarborgen. Voor het noodzakelijke onderhoud heeft Trustedaccountant.nl
        duidelijke afspraken gemaakt met haar leverancier en klanten. Optimale
        back-up procedures zorgen voor een optimale continuïteit waarbij we
        uitgaan van een ‘’fair use’’ handelswijze van onze klanten.
        Beschikbaarheid “De Backoffice wordt gehost op systemen van
        professionele Nederlandse datacenters. Deze datacenters hebben een
        netwerkbeschikbaarheid van 95%. De beschikbaarheid en performance van
        “De Backoffice” worden continu gemonitord. Als er sprake is van
        onderhoud of een storing dan zal de klant hiervan hiervan op de hoogte
        worden gesteld. In de volgende situaties kan “De Backoffice” niet
        beschikbaar zijn:<br />
        ·Preventief onderhoud;<br />
        ·Installeren nieuwe versie “de Backoffice”;<br />
        ·Verhelpen van storingen aan de programmatuur die onder de
        verantwoordelijkheid van de klant valt;<br />
        ·Onderhoud dat met de klant is kortgesloten;<br />
        ·Calamiteiten als gevolg van natuurrampen en andere overmacht
        situaties.<br />
      </v-card-text>
      <v-card-title class="primary--text"> Onderhoud </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        De klant wordt minimaal 5 dagen van tevoren geïnformeerd als “de
        Backoffice” mogelijk niet beschikbaar is. De werkzaamheden worden
        uitgevoerd tussen 21:00 en 07:00 uur of in het weekend. Incidentele
        patches en hotfixes worden automatisch en zonder vooraankondiging ´s
        nachts uitgevoerd.
      </v-card-text>
      <v-card-title class="primary--text"> Performance </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        “De Backoffice” behoort een goede performance te hebben, gelijkwaardig
        aan een lokale of netwerkinstallatie, maar is afhankelijk van de
        internetverbinding en inrichting van de omgeving van de klant. Ter
        verbetering van de performance worden grote verwerkingstaken, in een
        wachtrij geplaatst en op de achtergrond uitgevoerd. Bij
        performancehinder kan contact gezocht worden met de servicedesk.
      </v-card-text>
      <v-card-title class="primary--text"> Back-up & restore </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        1 keer per dag wordt er een back-up gemaakt van de volledige
        productieomgeving: Dag back-ups wordt na 7 dagen verwijderd. Wekelijkse
        back-ups worden 6 weken bewaard Maandelijkse back-ups worden 18 maanden
        bewaard Back-up kan op verzoek teruggezet worden. Gemiddelde duur van
        het terugzetten van een omgeving is 4 uur. Maandelijkse backups worden
        indien gewenst kan een back-up van de omgeving via een incident
        opgevraagd worden voor lokaal gebruik of archivering. Op deze aanvragen
        hanteert Trustedaccountant.nl een fair use beleid om grote datastromen
        te voorkomen. Monitoring Op “de Backoffice” worden systemen, processen
        en gebruikers gemonitord met als doel: Storingen te voorkomen of in een
        vroeg stadium op te lossen. Monitoring is gericht op het tijdig
        ontdekken van storingen en ongewenst gedrag. Er is altijd, ook ‘s
        nachts, een Trustedaccountant.nl medewerker beschikbaar om storingen en
        andere calamiteiten direct op te lossen. Controle op misbruik is
        onderdeel van de (dagelijkse) standaard monitoringswerkzaamheden.
        Verzamelen van algemene gebruikersstatistieken, zoals responsetijden.
        Deze informatie wordt geanalyseerd en mogelijk ter verbetering besproken
        met de klant. Verzamelen van anonieme statistieken uit de klantomgeving
        ter verbetering van onze producten en diensten. Beveiliging en toegang
        “De Backoffice” hanteert een ‘defense in depth’ strategie: Er zijn dus
        meerdere beveiligingslagen van toepassing. Mocht één van de lagen falen,
        dan zal de volgende laag alsnog bescherming bieden. Hiermee wordt de
        beschikbaarheid, integriteit en vertrouwelijkheid van de applicatie
        gewaarborgd. Trustedaccountant.nl gebruikt cryptografische maatregelen
        (versleuteling) om de vertrouwelijkheid van de informatie te beschermen
        en om de authenticiteit van gebruikers te kunnen vaststellen.
        Continuïteit “De Backoffice” beschikt over calamiteitenprocedures om
        verlies van data door systeemuitval, fysieke vernietiging of anderszins
        te voorkomen en het herstel van zulke data te bevorderen. Iedere klant
        krijgt een datacenter toegewezen. De datacenters zijn voorzien van
        redundante technieken, zodat uitval van enkele servers of storage niet
        direct leidt tot een calamiteit. Indien het nodig zal er worden
        uitgeweken naar het andere datacenter.
      </v-card-text>
      <v-card-title class="primary--text">
        RPO/RTO bij calamiteiten
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        In het geval van een complete uitval van een datacenter zijn er geen
        computerresources meer beschikbaar. Dit treft dan grofweg de helft van
        de klanten. Er zal op dat moment extra computercapaciteit in het andere
        datacenter beschikbaar worden gesteld. De RPO (recovery point objective)
        is de maximale tijd dat er sprake kan zijn van dataverlies bij een
        complete uitval. Door de wijze van back-up is deze bij “de Backoffice”
        maximaal 6 uur. De RTO (recovery time objective) is de tijd die nodig is
        om de beschikbare back-up beschikbaar te stellen. De tijd die hiervoor
        nodig is, is niet bekend. Voor individuele herstelwerkzaamheden als
        gevolg van bijvoorbeeld menselijke fouten vanuit de klantgebruiker is er
        altijd een back-up van maximaal 6 uur oud aanwezig. Er kunnen storingen
        zijn met verschillende oorzaken. Bij storingen zal Trustedaccountant.nl
        alles, wat tot haar mogelijkheden behoort, in het werk stellen om de
        dienst weer beschikbaar te krijgen voor de klant. Continuïteit
        datacenter bij faillissement Met het datacenter zijn aanvullende
        (contractuele) afspraken gemaakt die eraan moeten bijdragen dat klanten
        van Trustedaccountant.nl toegang tot hun data behouden in geval van een
        faillissement: Het datacenter zal de dienstverlening aan
        Trustedaccountant.nl niet staken in geval van een faillissement van het
        datacenter, voordat een continuïteitsplan is overeengekomen met de
        curator. Bij een faillissement van Trustedaccountant.nl zal het
        datacenter tot een minimale periode van 2 weken na datum faillissement
        de dienstverlening niet staken. Voordat de dienstverlening wordt
        gestaakt, zal het datacenter in overleg treden met de curator voor het
        behoud van de dienstverlening en het zeker stellen van de financiële
        verplichtingen.
      </v-card-text>
      <v-card-title class="primary--text"> Fair use </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl hanteert een "fair use principe". Dit wordt
        toegepast op het gebruik en de inzet van onderdelen van “de Backoffice”
        anders dan waarvoor ze bedoeld zijn. Trustedaccountant.nl neemt contact
        op met de klant wanneer er in strijd met het fair use principe gehandeld
        wordt en zal in overleg met de klant zoeken naar een oplossing. Pas
        wanneer in overleg met de klant geen oplossing gevonden kan worden heeft
        Trustedaccountant.nl het recht om het gebruik van “de Backoffice” te
        stoppen.
      </v-card-text>
      <v-card-title class="primary--text"> Support </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        De medewerkers van de servicedesk van Trustedaccountant.nl verhelpen
        problemen en beantwoorden vragen over “de Backoffice”. Via de e-mail
        administratie@trustedaccountant.nl of telefonisch kan een incident
        ingestuurd worden. Elk incident krijgt een prioriteit toegewezen.
        Algemene ondersteuning Elke melding aan de Servicedesk wordt een
        `incident’ genoemd. Een incident kan een fout, storing, wens of
        gebruikersvraag zijn. De klant kan zelf bepalen wie namens hun
        organisatie gemachtigd zijn om contact op te mogen nemen met de
        Servicedesk. De Supportmedewerker controleert dit ook bij ieder
        incident. Wanneer een gebruiker géén contactpersoon is bij de
        organisatie kan er geen ondersteuning geleverd worden door de
        Supportmedewerker. Trustedaccountant.nl verwacht dat de klant dit zelf
        onderhoudt, omdat de klant het beste kan bepalen wie er gemachtigd zijn.
        Trustedaccountant.nl staat open voor feedback. De klant kan via telefoon
        of e-mail zijn mening over Trustedaccountant.nl geven en/of een klacht
        insturen. Trustedaccountant.nl reageert binnen 1 dag op de klacht.
        Prioriteiten en hersteltijden In principe worden incidenten direct
        opgepakt. In de meeste gevallen betreffen dit vragen die te maken hebben
        met kennis over het product. De meeste van deze vragen worden dan vaak
        ook dezelfde dag nog afgehandeld. Indien het een wens betreft dan wordt
        deze ingestuurd, maar hierbij kan niet altijd worden aangegeven wanneer
        de wens precies wordt vervuld. <br />
        Elk incident krijgt een prioriteit toegewezen. Deze wordt toegekend door
        de Supportmedewerker die het incident in behandeling neemt. De
        richtlijnen die we hierbij hanteren zijn: ·Prio5: Wensen: Geen
        specifieke reactietijd;<br />
        ·Prio4: Informatieve vragen: Dezelfde dag of de volgende dag (in geval
        van uitzonderlijke drukte wordt de klant daarvan op de hoogte
        gesteld);<br />
        ·Prio3: Niet productie verstorende problemen: Hersteltijd binnen 1
        werkweek;<br />
        ·Prio2: Productie verstorende problemen: Hersteltijd/workaround binnen 1
        werkdag, voor zover dat mogelijk is;<br />
        ·Prio1: Productie verstorende problemen First Class:
        Hersteltijd/workaround binnen 4 uur, voor zover dat mogelijk is.<br />
      </v-card-text>
      <v-card-title class="primary--text"> Signalering </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl signaleert het aantal gebruikersvragen en de
        contactmomenten met de Servicedesk. De Supportmedewerker bepaalt of er
        contact wordt opgenomen met de klant om de oorzaak te achterhalen. Bij
        veel gebruikersvragen of veelvuldig contact met het Support Center zal
        Customer Care samen met de klant een oplossing zoeken om dit in de
        toekomst te voorkomen.<br />
      </v-card-text>
      <v-card-title class="primary--text"> Openingstijden </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Telefonisch is de servicedesk bereikbaar op maandag t/m donderdag van
        09:00 - 17:00 uur en op vrijdag van 09:00 - 16:00 uur.
      </v-card-text>
      <v-card-title class="primary--text"> Product </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl voorziet de klant van een product dat continu wordt
        doorontwikkeld en uitgeleverd met nieuwe functionaliteiten,
        verbeteringen en wettelijke aanpassingen. Dit product heet “de
        Backoffice”. In dit onderdeel staat informatie over de ontwikkeling en
        Versiebeheer, de systeemeisen en productondersteuning en informatie over
        de lokale installatie van Profit.
      </v-card-text>
      <v-card-title class="primary--text"> Productbeschrijving </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Diensten en verantwoordelijkheden Trustedaccountant.nl ontwikkelt en
        levert de software en kan in de meeste gevallen zorg dragen voor de
        implementatie van deze software bij haar klanten. Klanten zijn zelf
        verantwoordelijk voor een correcte inrichting van de applicatie.
        Trustedaccountant.nl ondersteunt hierin met behulp van templates op
        basis van best-practice. De applicatie wordt via Trustedaccountant.nl
        gehost en deze dienst is inclusief. Eigendomsrechten Het intellectuele
        eigendomsrecht van het product berust, en blijft berusten, bij
        Trustedaccountant.nl. Als een derde beweert dat het intellectuele
        eigendomsrecht van de programmatuur bij hem ligt, zal
        Trustedaccountant.nl de klant vrijwaren. Een voorwaarde daarvoor is wel
        dat de klant Trustedaccountant.nl hierover zo spoedig mogelijk
        informeert, medewerking verleent aan onderzoek en de afhandeling van de
        zaak verder geheel overlaat aan Trustedaccountant.nl. Indien de rechter
        vaststelt dat het intellectuele eigendom inderdaad bij een derde ligt,
        zal Trustedaccountant.nl ervoor zorgen dat de klant gebruik kan blijven
        maken van de programmatuur of hem voorzien van gelijkwaardige software.
        Het eigendomsrecht van de ingevoerde data en de door de applicatie
        gegenereerde data berust bij de klant. Trustedaccountant.nl mag en zal
        zich geen rechten toe-eigenen aan de klantdata. Een licentie geeft geen
        recht op de zogenoemde broncode. Voor een Escrow-overeenkomst kan een
        aparte afspraak gemaakt worden.
      </v-card-text>
      <v-card-title class="primary--text">
        Ontwikkeling en versiebeheer
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        De software wordt continu doorontwikkeld en uitgeleverd met nieuwe
        functionaliteiten, verbeteringen en wettelijke aanpassingen. Globaal
        hanteert Trustedaccountant.nl het volgende uitleveringsschema: Versie:
        2-4 keer per jaar; Patch: Dagelijks indien van toepassing. Elke Versie
        wordt voorzien van Releasenotes. Hierin wordt beschreven welke
        onderdelen zijn aangepast. Wettelijke aanpassingen en wijzigingen in de
        programmatuur worden alleen doorgevoerd in de laatste, meest actuele
        versie. Update nieuwe versie De update naar een nieuwe versie zal
        gefaseerd uitgevoerd worden. Een klant wordt ingedeeld in een
        tijdsschema en kan ervoor kiezen eerder of later over te gaan. De datum
        van migratie zal minimaal 5 dagen van tevoren definitief gemaakt worden.
        De totale migratieduur van de versie is 2-3 weken. Trustedaccountant.nl
        is in haar uitleveringsbeleid afhankelijk van wettelijke wijzigingen
        door overheid en instanties. Doorgaans worden wettelijke wijzigingen
        kort voor de ingangsdatum of zelfs met terugwerkende kracht definitief
        gemaakt. Trustedaccountant.nl probeert tijdig te anticiperen op
        wettelijke wijzigingen in de software. Voor updates met wettelijke
        wijzigingen die voor een bepaalde datum geïnstalleerd moeten zijn, kan
        Trustedaccountant.nl afwijken van het uitleverbeleid. Bij de migratie
        van de ene versie naar de volgende zal de data tijdelijk niet
        beschikbaar zijn. De tijd dat de data niet beschikbaar is, is erg
        afhankelijk van een aantal factoren zoals: soort dataconversie, grote
        van database, hoeveelheid aan te passen records. Ervaring leert dat de
        maximale tijd ligt tussen de 5 minuten en 2,5 uur. Deze werkzaamheden
        worden zoveel mogelijk buiten kantoortijden (ma-vr: 7:00 – 18:00)
        uitgevoerd en ruim van tevoren gecommuniceerd.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "profileAlg",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeWin() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>

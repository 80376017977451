<template>
  <div class="leftPanel mt-5">
    <v-row class="ml-5 mt-5 mr-5">
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_1a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Noodplan BV</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Met dit Noodplan breng je in een werkmaatschappij in kaart, waarbij
            de wensen van de ondernemer gekoppeld worden aan de feitelijke
            bedrijfsinformatie. Hieruit volgt dan dat er verschillende
            maatregelen genomen kunnen worden om de rol van de ondernemer
            tijdens een noodsituatie (tijdelijk) waar te nemen en de
            eigendomsstrategie van de ondernemer tot uitvoering te brengen.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_2a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text"
            >Noodplan Personal Holding</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            Dit Noodplan beschrijft de positie van de Personal Holding ten
            opzichte van de werkmaatschappijen, de rol van de ondernemer als
            bestuurder en alle afspraken met betrekking tot het vermogen dat
            door de personal holding wordt beheerd. Een nieuwe bestuurder weet
            als opvolger bijvoorbeeld hoe de leiding over de werkmaatschappijen
            moet worden ingevuld.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_3a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Noodplan VOF</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Per vennoot wordt inzichtelijk welke rol de vennoot heeft binnen het
            bedrijf, welke afspraken zijn gemaakt met de andere vennoten in een
            crisissituatie en geeft een volledig overzicht van het bedrijf. Een
            levenspartner kan het Noodplan gebruiken bij een crisissituatie om
            de belangen van de vennoot te behartigen.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_4a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text"
            >Noodplan Eenmanszaak</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            De rol van de ondernemer is cruciaal in het bedrijf. Vandaar dat een
            volledige weergave van de taken en verantwoordelijkheden, de
            belangrijkste stakeholders en activiteiten en de wensen van de
            ondernemer bij een crisissituatie centraal staan. Het geeft de
            waarnemer of opvolger een volledig beeld van wat er dient te
            gebeuren als de ondernemer (tijdelijk) geen leiding meer kan geven.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "noodCseg",
};
</script>

<style scoped></style>

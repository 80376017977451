<template>
  <v-main>
    <VideoComponentProfiel />
    <profile-aseg />
    <white-label />
    <Footer />
  </v-main>
</template>

<script>
import Footer from "@/components/footer";
import VideoComponentProfiel from "@/components/video/VideoComponentProfiel";
import profileAseg from "@/components/profile/profileAseg";
import WhiteLabel from "@/components/whiteLabel";
export default {
  name: "Profiel",
  components: {
    WhiteLabel,
    Footer,
    VideoComponentProfiel,
    profileAseg,
  },
};
</script>
<style scoped></style>
